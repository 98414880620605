import { useWindowScroll } from "../../util/useWindowScroll";
import "./WhatsappButton.css";
import { BsWhatsapp } from "react-icons/bs";
import { useLocation } from "react-router-dom";

const WhatsappButton = ({ onClick }) => {
  // colors #211c24 #ffffffd9
  const { pathname } = useLocation();
  const scrollY = useWindowScroll();
  return (
    <BsWhatsapp
      onClick={onClick}
      style={{
        color: pathname === "/main" && scrollY === 0 ? "#ffffffd9" : " #211c24",
      }}
      className="whatsappButton"
    />
  );
};

export default WhatsappButton;
