import { Wrapper } from "@googlemaps/react-wrapper";
import { Container } from "@mui/system";
import { Card, Col, Row } from "antd";

import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import CustomForm from "../../components/Form/CustomForm.jsx";
import useWindowSize from "../../util/useWindowSize.js";

import { SiInstagram, SiFacebook } from "react-icons/si";
import "./Iletisim.css";
import Spinner from "../../components/UI/Spinner.js";

import { Fade } from "react-reveal";

const Iletisim = () => {
  const { t } = useTranslation();
  const size = useWindowSize();
  const [isLoading, setIsLoading] = useState(false);
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <Container
          disableGutters={size.width < 480 ? true : false}
          maxWidth="lg"
        >
          <Helmet>
            <title>{t("headerContacts")}</title>
            <meta name="description" content={t("metaIletisim")} />
            <meta name="keywords" content={t("keywordIletisim")} />
          </Helmet>

          <div className="iletisimTitle">{t("contactsTitle")}</div>

          <Card className="iletisimCard">
            <Row gutter={40}>
              <Col className="iletisimColumnBorder" xs={24} lg={12} xl={12}>
                <CustomForm setIsLoading={setIsLoading} />
              </Col>

              <Col xs={24} lg={12} xl={12} className="iletisimColWrapInfo">
                <div className="infoWrapper">
                  <div>
                    <div className="iletisimInfoTitle iletisimEmailBorder">
                      Yavuz Plastik
                    </div>
                    <div className="iletisimInfoText">
                      Davraz mahallesi 138. Cadde 3994 Sokak No 2/10
                    </div>
                    <div className="iletisimEmailBorder">
                      <div className="iletisimInfoSubtitle">
                        {t("contactsFormMail")}
                      </div>
                      <div className="iletisimInfoText">
                        yavuzplastik1973@gmail.com
                      </div>
                    </div>
                    <div className="iletisimEmailBorder">
                      <div className="iletisimInfoSubtitle">
                        {t("contactsFormPhone")}
                      </div>
                      <div>
                        <div className="iletisimInfoText">
                          +90 544 301 61 69
                        </div>
                        <div>+90 507 702 70 07</div>
                      </div>
                    </div>
                  </div>
                  <div className="instagramWrapper">
                    <div
                      onClick={() =>
                        window.open(
                          "https://www.instagram.com/yavuz_plastik32/?hl=en"
                        )
                      }
                    >
                      <SiInstagram className="instagramIcon" size={36} />
                      <div>Instagram</div>
                    </div>
                    <div
                      onClick={() =>
                        window.open("https://www.facebook.com/Yavuzplastik.22/")
                      }
                      className="iletisimFacebookIcon"
                    >
                      <SiFacebook className="instagramIcon" size={36} />
                      <div>Facebook</div>
                    </div>
                  </div>
                </div>

                <div>
                  <iframe
                    title="Map"
                    width="100%"
                    height="500px"
                    frameborder="0"
                    marginheight="0"
                    marginwidth="0"
                    src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=37.775975,%2030.563508+(Yavuz%20Plastik)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                  >
                    <a href="https://www.maps.ie/distance-area-calculator.html">
                      distance maps
                    </a>
                  </iframe>
                </div>
              </Col>
            </Row>
          </Card>
        </Container>
      )}
    </>
  );
};

export default Iletisim;
