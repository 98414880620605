import { configureStore } from "@reduxjs/toolkit";
import productsSlice from "./productsSlice";
import adminReducer from "./slice";

export const store = configureStore({
  reducer: {
    admin: adminReducer,
    products: productsSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
