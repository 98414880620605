import { Col, Input, Row } from "antd";
import React from "react";

import "./DashboardForm.css"

const DashboardForm = ({ input, setInput }) => {

  return (
    <>
      <Row gutter={24}>
        <Col span={12}>
          <div className="dashboardFormTitle">Başlık</div>
          <Input
            onChange={({ target }) =>
              setInput((prev) => {
                return {
                  ...prev,
                  tr: { ...prev.tr, baslık: target.value },
                };
              })
            }
            value={input.tr.baslık}
            placeholder="Başlık Türkçe Olarak"
            className="dashboardFormInput"
          />
          <Input
            onChange={({ target }) =>
              setInput((prev) => {
                return {
                  ...prev,
                  en: { ...prev.en, baslık: target.value },
                };
              })
            }
            value={input.en.baslık}
            placeholder="Başlık İngilizce Olarak"
            className="dashboardFormInput"
          />
          <Input
            onChange={({ target }) =>
              setInput((prev) => {
                return {
                  ...prev,
                  gr: { ...prev.gr, baslık: target.value },
                };
              })
            }
            value={input.gr.baslık}
            placeholder="Başlık Almanca Olarak"
            className="dashboardFormInput"
          />
        </Col>
        <Col span={12}>
          <div className="dashboardFormTitle">Değer</div>
          <Input
            onChange={({ target }) =>
              setInput((prev) => {
                return {
                  ...prev,
                  tr: { ...prev.tr, deger: target.value },
                };
              })
            }
            value={input.tr.deger}
            placeholder="Değer Türkçe Olarak"
            className="dashboardFormInput"
          />
          <Input
            onChange={({ target }) =>
              setInput((prev) => {
                return {
                  ...prev,
                  en: { ...prev.en, deger: target.value },
                };
              })
            }
            value={input.en.deger}
            placeholder="Değer İngilizce Olarak"
            className="dashboardFormInput"
          />
          <Input
            onChange={({ target }) =>
              setInput((prev) => {
                return {
                  ...prev,
                  gr: { ...prev.gr, deger: target.value },
                };
              })
            }
            value={input.gr.deger}
            placeholder="Değer Almanca Olarak"
            className="dashboardFormInput"
          />
        </Col>
      </Row>
    </>
  );
};

export default DashboardForm;
