import { Container } from "@mui/system";
import { Card } from "antd";
import React from "react";
import "./Footer.css";
import { IoCall, IoLocationSharp } from "react-icons/io5";
import { IoMdMail } from "react-icons/io";
import { SiInstagram, SiFacebook } from "react-icons/si";
import { useTranslation } from "react-i18next";

import { Fade } from "react-reveal"; 
import ReactWhatsapp from "react-whatsapp"; 
import WhatsappButtonFooter from "../Whatsapp/WhatsappButtonFooter";

const Footer = () => {
  const openWithGoogleMaps = () => {
    window.open(
      "https://www.google.com/maps/search/?api=1&query=37.772085, 30.564364"
    );
  };

  const { t } = useTranslation();

  return (
    <footer className="footerWrapper">
      <Container maxWidth="lg">
        <Fade top duration={1250}>
          <div className="footerTitle">{t("footer-title")}</div>
        </Fade>
        <div className="footerCardContainer">
          <Fade left duration={1000}>
            <Card
              onClick={() => (window.location.href = "tel:+905543016169")}
              className="footerCard"
            >
              <div className="footerCardInnerWrap">
                <IoCall color="white" size={36} />
                <div className="footerCardInnerInfo">+90 544 301 61 69</div>
              </div>
            </Card>
          </Fade>
          <Fade bottom duration={1250}>
            <Card
              className="footerCard"
              onClick={() =>
                (window.location = "mailto:yavuzplastik1973@gmail.com")
              }
            >
              <div className="footerCardInnerWrap">
                <IoMdMail color="white" size={36} />
                <div className="footerCardInnerInfo">
                  yavuzplastik1973@gmail.com
                </div>
              </div>
            </Card>
          </Fade>
          <Fade right duration={1000}>
            <Card className="footerCard" onClick={openWithGoogleMaps}>
              <div className="footerCardInnerWrap">
                <IoLocationSharp color="white" size={36} />
                <div className="footerCardInnerInfo">
                  {t("footer-location")}
                </div>
              </div>
            </Card>
          </Fade>
        </div>
        <div className="socialMediaWrapper">
          <Fade top duration={1000}>
            <SiInstagram
              onClick={() =>
                window.open("https://www.instagram.com/yavuz_plastik32/?hl=en")
              }
              className="socialMediaLogo1"
              size={40}
              color="#f6f6f6"
            />
            <SiFacebook
              onClick={() =>
                window.open("https://www.facebook.com/Yavuzplastik.22/")
              }
              className="socialMediaLogo2"
              size={40}
              color="#f6f6f6"
            />
            <ReactWhatsapp
              number="+905443016169"
              element={WhatsappButtonFooter}
            />
          </Fade>
        </div>
      </Container>
      <Fade bottom duration={1250}>
        <div className="footerBy">{t("footer-arz")}</div>
      </Fade>
    </footer>
  );
};

export default Footer;
