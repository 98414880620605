import { Container } from "@mui/material";
import React from "react";

import image from "../../public/images/background/bg-1.jpg";

import "./WorldStandard.css";
import { t } from "i18next";
import { Fade } from "react-reveal";
const WorldStandard = () => {
  return (
    <Container maxWidth="lg" className="world_standard">
      <Fade top>
        <h3>{t("world-standard-title")}</h3>
      </Fade>
      <div>
        <Fade left>
          <img src={image} alt="mountain" />
        </Fade>
        <Fade right>
          <p>{t("world-standard-text")}</p>
        </Fade>
      </div>
    </Container>
  );
};

export default WorldStandard;
