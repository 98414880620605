import { Button, Form, Card, Input, message } from "antd";
import { signInWithEmailAndPassword } from "firebase/auth";
import React, { useState } from "react";
import { auth } from "../../../firebase";
import Spinner from "../../UI/Spinner";

import "./AdminForm.css";

const AdminForm = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = async (values) => {
    try {
      setIsLoading(true);
      await signInWithEmailAndPassword(auth, values.username, values.password);
      setIsLoading(false);
    } catch (error) {
      message.error("E-Posta veya Parola Hatalı!");
      setIsLoading(false);
      form.resetFields();
    }
    form.resetFields();
  };
  const onFinishFailed = (errorInfo) => {
    message.warn("İsteğiniz Gerçekleştirilemedi!")
  };
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <Card className="adminCard">
          <Form
            form={form}
            name="basic"
            labelCol={{
              span: 5,
            }}
            wrapperCol={{
              span: 16,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Kullanıcı Adı"
              name="username"
              rules={[
                {
                  required: true,
                  message: "Kullanıcı Adı gereklidir!",
                },
              ]}
            >
              <Input type="email" className="adminFormInput" />
            </Form.Item>

            <Form.Item
              label="Şifre"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Şifre gereklidir!",
                },
              ]}
            >
              <Input.Password className="adminFormInput" />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Button
                className="adminFormButton"
                type="primary"
                htmlType="submit"
              >
                Giriş Yap
              </Button>
            </Form.Item>
          </Form>
        </Card>
      )}
    </>
  );
};

export default AdminForm;
