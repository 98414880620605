import { Card, Col } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

import "./ListUrun.css";

const ListUrunler = ({ index, title, image, id }) => {
  const navigate = useNavigate();
  return (
    <Col key={index} className="listUrunCol" sm={24} md={12} lg={12} xl={8}>
      <Card
        className="ürünlerimizCard"
        onClick={() => navigate(`/products/${id}`)}
      >
        <div>
          <img className="listUrunImg" src={image} alt="title" />
          <div className="listUrunTitle">{title}</div>
        </div>
      </Card>
    </Col>
  );
};

export default ListUrunler;
