import { useWindowScroll } from "../../util/useWindowScroll";
import "./WhatsappButton.css";
import { BsWhatsapp } from "react-icons/bs";
import { useLocation } from "react-router-dom";

const WhatsappButtonFooter = ({ onClick }) => {
  // colors #211c24 #ffffffd9
  return (
    <BsWhatsapp
      onClick={onClick}
      size={40}
      style={{
        color: "#f6f6f6",
      }}
      className="whatsappLogoFooter"
    />
  );
};

export default WhatsappButtonFooter;
