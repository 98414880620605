import { Spin } from "antd";
import React from "react";
import "./Spinner.css";

import { LoadingOutlined } from "@ant-design/icons";

const Spinner = ({ message }) => {
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 48,
        color: "#211c24",
      }}
      spin
    />
  );
  return (
    <div className="spinner">
      <Spin indicator={antIcon} />
      <div className="spinnerMessage">{message}</div>
    </div>
  );
};

export default Spinner;
