import React, { useEffect, useState } from "react";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import { useSelector } from "react-redux";
import { t } from "i18next";

import "./UrunlerimizTree.css";

const UrunlerimizTree = ({ takeCategoryTitle }) => {
  const [kapaklar, setKapaklar] = useState([]);
  const [siseler, setSiseler] = useState([]);
  const [spreyler, setSpreyler] = useState([]);
  const [pompalar, setPompalar] = useState([]);
  const [preformlar, setPreformlar] = useState([]);

  const [expanded, setExpanded] = useState([]);

  const getData = async (type, whichState) => {
    const productRef = collection(db, type);
    const resData = await getDocs(productRef);

    whichState(
      resData.docs.map((doc) => {
        return doc.data();
      })
    );
  };

  useEffect(() => {
    getData("kapaklar", setKapaklar);
    getData("şişeler", setSiseler);
    getData("preformlar", setPreformlar);
    getData("spreyler", setSpreyler);
    getData("pompalar", setPompalar);
  }, []);

  const lang = useSelector(({ admin }) => admin.whichLang);

  return (
    <>
      <TreeView
        aria-label="file system navigator"
        className="treeview"
        expanded={expanded}
      >
        <TreeItem
          className="treeItem"
          nodeId="1"
          label={<div className="treeDiv">{t("products-all-products")}</div>}
          onClick={() => {
            if (expanded.includes("1")) {
              setExpanded([]);
            } else {
              setExpanded(["1"]);
            }
            takeCategoryTitle("tumUrunler");
          }}
        />
        <TreeItem
          className="treeItem"
          onClick={() => {
            if (expanded.includes("2")) {
              setExpanded([]);
            } else {
              setExpanded(["2"]);
            }
            takeCategoryTitle("şişeler");
          }}
          nodeId="2"
          label={<div className="treeDiv">{t("products-tree-bottles")}</div>}
        >
          {siseler &&
            siseler.map((data, index) => (
              <TreeItem
                className="treeItem"
                onClick={() => takeCategoryTitle(data.tr)}
                nodeId={index + 20}
                label={
                  (lang === "en" && <div className="treeDiv">{data.en}</div>) ||
                  (lang === "tr" && <div className="treeDiv">{data.tr}</div>) ||
                  (lang === "gr" && <div className="treeDiv">{data.gr}</div>)
                }
              />
            ))}
        </TreeItem>
        <TreeItem
          className="treeItem"
          onClick={() => {
            if (expanded.includes("3")) {
              setExpanded([]);
            } else {
              setExpanded(["3"]);
            }
            takeCategoryTitle("preformlar");
          }}
          nodeId="3"
          label={<div className="treeDiv">{t("products-tree-preform")}</div>}
        >
          {preformlar &&
            preformlar.map((data, index) => (
              <TreeItem
                className="treeItem"
                onClick={() => takeCategoryTitle(data.tr)}
                nodeId={index + 30}
                label={
                  (lang === "en" && <div className="treeDiv">{data.en}</div>) ||
                  (lang === "tr" && <div className="treeDiv">{data.tr}</div>) ||
                  (lang === "gr" && <div className="treeDiv">{data.gr}</div>)
                }
              />
            ))}
        </TreeItem>
        <TreeItem
          onClick={() => {
            if (expanded.includes("4")) {
              setExpanded([]);
            } else {
              setExpanded(["4"]);
            }
            takeCategoryTitle("kapaklar");
          }}
          className="treeItem"
          nodeId="4"
          label={<div className="treeDiv">{t("products-tree-kapak")}</div>}
        >
          {kapaklar &&
            kapaklar.map((data, index) => (
              <TreeItem
                onClick={() => takeCategoryTitle(data.tr)}
                className="treeItem"
                nodeId={index + 40}
                label={
                  (lang === "en" && <div className="treeDiv">{data.en}</div>) ||
                  (lang === "tr" && <div className="treeDiv">{data.tr}</div>) ||
                  (lang === "gr" && <div className="treeDiv">{data.gr}</div>)
                }
              />
            ))}
        </TreeItem>
        <TreeItem
          onClick={() => {
            if (expanded.includes("5")) {
              setExpanded([]);
            } else {
              setExpanded(["5"]);
            }
            takeCategoryTitle("spreyler");
          }}
          className="treeItem"
          nodeId="5"
          label={<div className="treeDiv">{t("products-tree-sprey")}</div>}
        >
          {spreyler &&
            spreyler.map((data, index) => (
              <TreeItem
                onClick={() => takeCategoryTitle(data.tr)}
                className="treeItem"
                nodeId={index + 50}
                label={
                  (lang === "en" && <div className="treeDiv">{data.en}</div>) ||
                  (lang === "tr" && <div className="treeDiv">{data.tr}</div>) ||
                  (lang === "gr" && <div className="treeDiv">{data.gr}</div>)
                }
              />
            ))}
        </TreeItem>
        <TreeItem
          onClick={() => {
            if (expanded.includes("6")) {
              setExpanded([]);
            } else {
              setExpanded(["6"]);
            }
            takeCategoryTitle("pompalar");
          }}
          className="treeItem"
          nodeId="6"
          label={<div className="treeDiv">{t("products-tree-pompa")}</div>}
        >
          {pompalar &&
            pompalar.map((data, index) => (
              <TreeItem
                onClick={() => takeCategoryTitle(data.tr)}
                className="treeItem"
                nodeId={index + 60}
                label={
                  (lang === "en" && <div className="treeDiv">{data.en}</div>) ||
                  (lang === "tr" && <div className="treeDiv">{data.tr}</div>) ||
                  (lang === "gr" && <div className="treeDiv">{data.gr}</div>)
                }
              />
            ))}
        </TreeItem>
      </TreeView>
    </>
  );
};

export default UrunlerimizTree;
