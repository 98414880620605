import React from "react";

import { AiFillStar, AiOutlinePercentage } from "react-icons/ai";
import { MdPerson } from "react-icons/md";
import { GiWorld } from "react-icons/gi";
import world from "../../public/images/earth.jpg";

import "./ExportLocation.css";
import { t } from "i18next";
import { Fade } from "react-reveal";
const ExportLocation = () => {
  return (
    <div className="export_location">
      <div className="export_location_image_info_wrapper">
        <Fade left>
          <img src={world} alt="world" />
        </Fade>
        <Fade right>
          <div>
            <h3>{t("export-location-title")}</h3>
            <p>{t("export-location-text")}</p>
            <h5>{t("export-location-countries")}</h5>
            <button
              onClick={() =>
                window.open(
                  "https://api.whatsapp.com/send/?phone=905443016169&text&type=phone_number&app_absent=0"
                )
              }
            >
              {t("export-location-button")}
            </button>
          </div>
        </Fade>
      </div>
      <div className="export_location_bottom">
        <Fade left>
          <div>
            <div className="mobile_border_right">
              <AiFillStar />
              <h5>35+</h5>
              <p>{t("export-location-experience")}</p>
            </div>
            <div>
              <MdPerson />
              <h5>35+</h5>
              <p>{t("export-location-personal")}</p>
            </div>
          </div>
        </Fade>
        <Fade right>
          <div>
            <div className="mobile_border_right">
              <AiOutlinePercentage />
              <h5>35+</h5>
              <p>{t("export-location-export-rate")}</p>
            </div>
            <div>
              <GiWorld />
              <h5>35+</h5>
              <p>{t("export-location-country-export")}</p>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default ExportLocation;
