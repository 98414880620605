import { Container } from "@mui/material";

import React from "react";
import AdminForm from "../../components/Form/adminForm/AdminForm";

import "./Admin.css";
import Dashboard from "../../components/dashboard/Dashboard.jsx";
import useWindowSize from "../../util/useWindowSize";
import { Helmet } from "react-helmet";
import { Tabs } from "antd";
import Analytics from "./Analytics/Analytics";

const Admin = ({ isAdminLogin }) => {
  const size = useWindowSize();
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Container disableGutters={size.width < 480 ? true : false}>
        {!isAdminLogin ? <AdminForm /> : <AdminTabs />}
      </Container>
    </>
  );
};

export default Admin;

const AdminTabs = () => {
  const onChange = (key) => {
    console.log(key);
  };
  const items = [
    {
      key: "1",
      label: `Ürün Ekleme`,
      children: <Dashboard />,
    },
    {
      key: "2",
      label: `Analiz`,
      children: <Analytics />,
    },
  ];
  return <Tabs className="admin_tabs" defaultActiveKey="2" items={items} onChange={onChange} />;
};
