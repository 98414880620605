import { Container } from "@mui/material";
import { Breadcrumb, Col, Row } from "antd";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ListUrunler from "../../components/Urunlerimiz/ListUrun";
import UrunInfo from "../../components/Urunlerimiz/UrunInfo";
import UrunlerimizTree from "../../components/Urunlerimiz/UrunlerimizTree";
import "./Urunlerimiz.css";

import { Fade } from "react-reveal";

const Urunlerimiz = () => {
  const [categoryTitle, setCategoryTitle] = useState("tumUrunler");
  const { t, i18n } = useTranslation();
  const products = useSelector(({ products }) => products.products);
  //  const lang = useSelector(({ admin }) => admin.whichLang);
  const lang = i18n.language;
  const takeCategoryTitle = (category) => {
    setCategoryTitle(category);
  };
  return (
    <Container maxWidth="lg">
      <Helmet>
        <title>{t("headerProducts")}</title>
        <meta name="description" content={t("metaUrunlerimiz")} />
        <meta name="keywords" content={t("keywordUrunlerimiz")} />
      </Helmet>
      <Fade top duration={1250}>
        <Breadcrumb className="urunlerimizBreadcrumb">
          <Breadcrumb.Item>
            <Link to="/anasayfa">{t("products-bread-main")}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/urunlerimiz">{t("products-bread-products")}</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Fade>
      <Row gutter={24}>
        <Col
          className="urunlerimizListCol"
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={6}
        >
          <Fade left duration={1250}>
            <UrunlerimizTree takeCategoryTitle={takeCategoryTitle} />
          </Fade>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={18}>
          <Fade top duration={1250}>
            <UrunInfo
              size={
                products.filter((d) => {
                  if (categoryTitle !== "tumUrunler") {
                    return d.data.altKategori === categoryTitle
                      ? d.data.altKategori === categoryTitle
                      : d.data.kategori === categoryTitle;
                  } else {
                    return d.data;
                  }
                }).length
              }
            />
          </Fade>
          <Row gutter={24}>
            {products
              .filter((d) => {
                if (categoryTitle !== "tumUrunler") {
                  return d.data.altKategori === categoryTitle
                    ? d.data.altKategori === categoryTitle
                    : d.data.kategori === categoryTitle;
                } else {
                  return d.data;
                }
              })
              .map((d, index) => (
                <ListUrunler
                  key={index}
                  id={d.id}
                  title={
                    (lang === "en" && d.data.isimler.en) ||
                    (lang === "tr" && d.data.isimler.tr) ||
                    (lang === "gr" && d.data.isimler.gr)
                  }
                  image={d.data.imagesUrl[0]}
                />
              ))}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Urunlerimiz;
