import React from "react";
import { Pie, PieChart } from "recharts";
import "./AnalyticsChart.css";

const AnalyticsChart = (data) => {
  const windowWidth = window.outerWidth;
  return (
    <div className="analytics_chart_container">
      <PieChart
        className="pie_chart"
        width={windowWidth <= 768 ? 300 : 400}
        height={windowWidth <= 768 ? 300 : 400}
      >
        <Pie
          dataKey="count"
          label={(entry) => entry.city}
          nameKey="city"
          startAngle={0}
          fontSize={windowWidth <= 768 ? 12 : 16}
          endAngle={360}
          data={data?.data}
          cx={windowWidth <= 768 ? "55%" : "50%"}
          cy="50%"
          outerRadius={windowWidth <= 768 ? 60 : 100}
          fill="#000000e6"
          letterSpacing={2}
        />
      </PieChart>
      <div className="chart_card_wrapper">
        <div>
          {data?.data?.map((d) => (
            <div className="analytics_chart_city_card">
              <h3>{d.city}</h3>
              <h2>{d.count}</h2>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AnalyticsChart;
