import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Header from "./components/Header/Header.jsx";
import Anasayfa from "./pages/Anasayfa/Anasayfa";
import Kurumsal from "./pages/Kurumsal/Kurumsal";
import Whatsapp from "./components/Whatsapp/Whatsapp";
import Iletisim from "./pages/Iletisim/Iletisim";
import Footer from "./components/Footer/Footer";
import Admin from "./pages/admin/Admin";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { onAuthStateChanged } from "firebase/auth";
import { setAdmin } from "./store/slice";
import { auth, db } from "./firebase";
import Urunlerimiz from "./pages/Urunlerimiz/Urunlerimiz";
import UrunDetay from "./pages/Urunlerimiz/UrunDetay";
import { collection, getDocs } from "firebase/firestore";
import { setAllProducts } from "./store/productsSlice";
import AdminCategory from "./pages/admin/AdminCategory";
import { StickNavStyle } from "./StickNavStyle";

import { StickyNav } from "react-js-stickynav";
import { useTranslation } from "react-i18next";
import { getClientGeolocation, getClientInfo } from "./util/httpMethods";
import { client, countPageViews, deleteActiveUser, setActiveUsers, setTotalUsers } from "./util/firebaseFunctions";

function App() {
  const [isAdmin, setIsAdmin] = useState(false);
  const location = useLocation();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const productRef = collection(db, "products");
  useEffect(() => {
    const getData = async () => {
      const resData = await getDocs(productRef);
      dispatch(
        setAllProducts(
          resData.docs.map((doc) => {
            return { data: doc.data(), id: doc.id };
          })
        )
      );
    };
    getData();
  }, []);

  onAuthStateChanged(auth, (currentUser) => {
    dispatch(setAdmin(currentUser));
  });

  const isAdminLogin = useSelector(({ admin }) => admin.admin);

  useEffect(() => {
    if (location.pathname === "/admin") {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [location]);

  /*  useEffect(() => {
    if (location.pathname === "/anasayfa") {
      if (scrollY === 0) {
        dispatch(setWhatsappColorWhite());
      }
    }
  }, [dispatch, location.pathname, scrollY]); */

  const [ipAddress, setIpAddress] = useState(null);

  //ACTIVE USERS
  useEffect(() => {
    const activeUsersHandler = async () => {
      const clientInfo = await getClientInfo();
      setIpAddress(clientInfo.ipString);
      const result = await getClientGeolocation();
      await client(clientInfo.ipString, result.city, result.countryCode);
      //firebase function
      await setActiveUsers(clientInfo.ipString, clientInfo.isMobile);
      await setTotalUsers(clientInfo.ipString, result.city, result.countryName);
    };
    activeUsersHandler();
  }, []);

  useEffect(() => {
    const handleTabClose = async (event) => {
      event.preventDefault();
      deleteActiveUser(ipAddress);
    };

    window.addEventListener("beforeunload", handleTabClose);

    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, [ipAddress]);
  //ACTIVE USERS END

  useEffect(() => {
    countPageViews();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      {StickNavStyle()}
      <div className="appContainer">
        {location.pathname === "/main" ? (
          <StickyNav length="40">
            <Header title={t("hero-title")} isAdminLogin={isAdminLogin} />
          </StickyNav>
        ) : (
          <Header isAdminLogin={isAdminLogin} />
        )}

        <Routes>
          <Route path="/main" element={<Anasayfa />} />
          <Route path="/about" element={<Kurumsal />} />
          <Route path="/products" element={<Urunlerimiz />} />
          <Route path="/products/:id" element={<UrunDetay />} />
          <Route path="/contact" element={<Iletisim />} />
          <Route
            path="/admin"
            element={<Admin isAdminLogin={isAdminLogin} />}
          />
          {isAdminLogin && (
            <Route path="/admin/category" element={<AdminCategory />} />
          )}

          <Route path="/" element={<Navigate to="/main" replace />} />
          <Route path="*" element={<Navigate to="/main" replace />} />
        </Routes>
      </div>
      {!isAdmin && <Whatsapp />}
      {!isAdmin && <Footer />}
    </>
  );
}

export default App;
