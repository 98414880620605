import { Container } from "@mui/material";
import React from "react";

import vd from "../../public/videos/main-video.mp4";
import "./Video.css";
const Video = ({ src }) => {
  return (
    <Container className="video_container">
      <video className="video" width={"100%"} autoPlay loop muted controls>
        <source src={vd} type="video/mp4" />
      </video>
    </Container>
  );
};

export default Video;
