import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  admin: null,
  whichLang: "tr",
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setAdmin: (state, action) => {
      state.admin = action.payload;
    },
    logoutAdmin: (state) => {
      state.admin = null;
    },
    setLanguage: (state, action) => {
      state.whichLang = action.payload;
    },
  },
});

export const { setAdmin, logoutAdmin, setLanguage } = adminSlice.actions;

export default adminSlice.reducer;
