import { Button } from "antd";
import { signOut } from "firebase/auth";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { auth } from "../../firebase";
import { logoutAdmin } from "../../store/slice";

import { AiOutlineClose } from "react-icons/ai";

import "./HeaderNav.css";
import LanguageSelector from "./LanguageSelector";

const HeaderNav = ({ isAdminLogin, burgerStatus, setBurgerStatus }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const logout = async () => {
    await signOut(auth);
    dispatch(logoutAdmin());
  };

  return (
    <div className="headerNavWrapper">
      <div className="headerNavInnerWrapper">
        {burgerStatus && (
          <div className="header_close_and_title_wrapper">
            <h3 className="header_nav_title">Yavuz Plastik</h3>
            <AiOutlineClose
              className="burger_close"
              size={36}
              color="#f6f6f6"
              onClick={() => setBurgerStatus(false)}
            />
          </div>
        )}
        <NavLink
          className={({ isActive }) =>
            isActive ? "headerActiveNavLink" : "headerNavLink"
          }
          to="/main"
        >
          {t("header-main")}
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive ? "headerActiveNavLink" : "headerNavLink"
          }
          to="/about"
        >
          {t("header-about")}
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive ? "headerActiveNavLink" : "headerNavLink"
          }
          to="/products"
        >
          {t("header-products")}
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive ? "headerActiveNavLink" : "headerNavLink"
          }
          to="/contact"
        >
          {t("header-contact")}
        </NavLink>
        {isAdminLogin && (
          <NavLink
            className={({ isActive }) =>
              isActive ? "headerActiveNavLink" : "headerNavLink"
            }
            to="/admin"
          >
            Admin
          </NavLink>
        )}
        {isAdminLogin && (
          <Button className="headerLogoutButton" onClick={logout}>
            Çıkış Yap
          </Button>
        )}

        <LanguageSelector />
      </div>
    </div>
  );
};

export default HeaderNav;
