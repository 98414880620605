import { Container } from "@mui/material";
import React from "react";

import containersImg from "../../public/images/containers.jpg";
import handshakeImg from "../../public/images/handshake.jpg";
import earthImg from "../../public/images/earth.jpg";
import "./Kurumsal.css";
import { t } from "i18next";
import { Helmet } from "react-helmet";
import { Fade } from "react-reveal";
const Kurumsal = () => {
  return (
    <Container>
      <Helmet>
        <title>{t("headerKurumsal")}</title>
        <meta name="description" content={t("metaKurumsal")} />
        <meta name="keywords" content={t("keywordKurumsal")} />
      </Helmet>
      <h3 className="about_title">{t("about")}</h3>
      <div className="about_section">
        <Fade left duration={1250}>
          <img src={containersImg} alt="" />
        </Fade>
        <Fade right duration={1250}>
          <div>
            <h3>{t("about-title-1")}</h3>
            <p>{t("about-text-1")}</p>
          </div>
        </Fade>
      </div>
      <div className="about_section about_section_reverse">
        <Fade left duration={1350}>
          <img src={handshakeImg} alt="" />
        </Fade>
        <Fade right duration={1250}>
          <div>
            <h3>{t("about-title-2")}</h3>
            <p>{t("about-text-2")}</p>
          </div>
        </Fade>
      </div>
      <div className="about_section">
        <Fade left duration={1350}>
          <img src={earthImg} alt="" />
        </Fade>
        <Fade right duration={1250}>
          <div>
            <h3>{t("about-title-3")}</h3>
            <p>{t("about-text-3")}</p>
          </div>
        </Fade>
      </div>
    </Container>
  );
};

export default Kurumsal;
