import React, { useEffect, useState } from "react";
import { Select } from "antd";

import TurkishFlag from "../../public/images/Flag_of_Turkey.svg.png";
import GermanFlag from "../../public/images/Flag_of_Germany.svg.png";
import EnglishFlag from "../../public/images/english_flag.png";

import "./LanguageSelector.css";
import { useTranslation } from "react-i18next";

const LanguageSelector = () => {
  const [open, setOpen] = useState(false);
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setOpen(false);
  };

  const scrollY = window.scrollY;

  useEffect(() => {
    setOpen(false);
  }, [scrollY]);

  return (
    <div className="language__selector__wrapper">
      <Select
        className="language__select"
        onChange={changeLanguage}
        open={open}
        onClick={() => setOpen(!open)}
        onBlur={() => setOpen(false)}
        placeholder={
          lang === "tr" ? (
            <div className="flag__wrapper">
              <img className="flag" src={TurkishFlag} alt="tr" />
            </div>
          ) : lang === "en" ? (
            <div className="flag__wrapper">
              <img className="flag" src={EnglishFlag} alt="en" />
            </div>
          ) : (
            <div className="flag__wrapper">
              <img className="flag" src={GermanFlag} alt="gr" />
            </div>
          )
        }
        dropdownStyle={{ backgroundColor: "#211c24", zIndex: 9999 }}
        options={[
          {
            value: "tr",
            label: (
              <div className="flag__wrapper">
                <img className="flag" src={TurkishFlag} alt="tr" />
              </div>
            ),
          },
          {
            value: "en",
            label: (
              <div className="flag__wrapper">
                <img className="flag" src={EnglishFlag} alt="en" />
              </div>
            ),
          },
          {
            value: "gr",
            label: (
              <div className="flag__wrapper">
                <img className="flag" src={GermanFlag} alt="gr" />
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default LanguageSelector;
