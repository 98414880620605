import React from "react";
import Hero from "../../components/Hero/Hero";
import WhoWeAre from "../../components/WhoWeAre/WhoWeAre";
import Card5 from "../../components/Card5/Card5";
import Video from "../../components/Video copy/Video";
import CustomCarousel from "../../components/Carousel/CustomCarousel";
import WhatDoWeDo from "../../components/WhatDoWeDo/WhatDoWeDo";
import ExportLocation from "../../components/ExportLocation/ExportLocation";
import WorldStandard from "../../components/WorldStandard/WorldStandard";

const Anasayfa = () => {
  return (
    <div>
      <Hero />
      <WhoWeAre />
      <Card5 />
      <Video />
      <CustomCarousel />
      <WhatDoWeDo />
      <ExportLocation />
      <WorldStandard />
    </div>
  );
};

export default Anasayfa;
