import React from "react";
import "./Hero.css";

import { Container } from "@mui/material";
import { t } from "i18next";
import { Fade } from "react-reveal";
const Hero = () => {
  return (
    <div className="hero">
      <Container maxWidth="xl" className="hero_container_mui">
        <Fade left>
          <h3>{t("hero-title")}</h3>
        </Fade>
        <Fade right>
          <h4>{t("hero-subtitle")}</h4>
        </Fade>
      </Container>
    </div>
  );
};

export default Hero;
