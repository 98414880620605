import {
  doc,
  getDoc,
  setDoc,
  deleteDoc,
  getDocs,
  collection,
} from "firebase/firestore";
import { db } from "../firebase";

// ACTIVE USERS
export const setActiveUsers = async (ip, isMobile) => {
  await setDoc(doc(db, "active_users", ip), {
    isMobile,
  });
};

export const getActiveUsers = async (setActiveUsers) => {
  const querySnapshot = await getDocs(collection(db, "active_users"));
  setActiveUsers(querySnapshot.size);
};

export const deleteActiveUser = async (ip) => {
  await deleteDoc(doc(db, "active_users", ip));
};
// ACTIVE USERS END

// PAGE VIEWS
export const countPageViews = async () => {
  const docRef = doc(db, "page_views", "page_views");
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data().pageViews);
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }

  await setDoc(doc(db, "page_views", "page_views"), {
    pageViews: docSnap.data().pageViews + 1,
  });
};

export const getPageViews = async (setPageViews) => {
  const docRef = doc(db, "page_views", "page_views");
  const docSnap = await getDoc(docRef);
  setPageViews(docSnap.data().pageViews);
  /*  if (docSnap.exists()) {
    return docSnap.data().pageViews;
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  } */
};

// CLIENT INFO
export const client = async (ip, countryCode, city) => {
  await setDoc(doc(db, "active_users", ip), {
    countryCode,
    city,
  });
};

// TOTAL USERS
export const setTotalUsers = async (ip, city, countryName) => {
  await setDoc(doc(db, "total_users", ip), {
    countryName,
    city,
  });
};

export const getAllUsers = async (setAllUsers, setAllUsersSize) => {
  const querySnapshot = await getDocs(collection(db, "total_users"));
  const usersInfo = [];
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    usersInfo.push(doc.data());
  });

  setAllUsers(usersInfo);

  if (setAllUsersSize) {
    setAllUsersSize(querySnapshot.size);
  }
};
