import { Container } from "@mui/material";
import { Button, Card, Form, Input, message, Select } from "antd";
import { addDoc, collection } from "firebase/firestore";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Spinner from "../../components/UI/Spinner";
import { db } from "../../firebase";
import useWindowSize from "../../util/useWindowSize";

import "./AdminCategory.css";

const AdminCategory = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [selectValue, setSelectValue] = useState(null);

  const [form] = Form.useForm();

  const onFinish = async (values) => {
    const ref = collection(db, selectValue);
    setIsLoading(true);

    try {
      await addDoc(ref, values);
      message.success("Alt Kategori Başarıyla Eklendi!");
    } catch (error) {
      message.error("Alt Kategori Eklenemedi");
    }

    setIsLoading(false);
    form.resetFields();
  };
  const onFinishFailed = (errorInfo) => {
    message.error("Bir hata meydana geldi!");
  };

  // FORM STUFF END

  const size = useWindowSize();

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Container
            disableGutters={size.width < 480 ? true : false}
            maxWidth="md"
          >
            <Card className="adminCategoryCard">
              <Form
                form={form}
                name="basic"
                className="adminCategoryForm"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  name="kategori"
                  rules={[
                    {
                      required: true,
                      message: "Kategori gereklidir!",
                    },
                  ]}
                >
                  <Select
                    className="adminCategoryInput"
                    placeholder="Kategoriler"
                    style={{
                      width: 200,
                    }}
                    onChange={(value) => setSelectValue(value)}
                    options={[
                      { value: "şişeler", label: "Şişeler" },
                      { value: "kapaklar", label: "Kapaklar" },
                      { value: "preformlar", label: "Preformlar" },
                      { value: "spreyler", label: "Spreyler" },
                      { value: "pompalar", label: "Pompalar" },
                    ]}
                  />
                </Form.Item>
                <Form.Item
                  name="tr"
                  rules={[
                    {
                      required: true,
                      message: "Bu alan gereklidir!",
                    },
                  ]}
                >
                  <Input
                    className="adminCategoryInput"
                    placeholder={`Alt Kategori Türkçe`}
                  />
                </Form.Item>
                <Form.Item
                  name="en"
                  rules={[
                    {
                      required: true,
                      message: "Bu alan gereklidir!",
                    },
                  ]}
                >
                  <Input
                    className="adminCategoryInput"
                    placeholder={`Alt Kategori İngilizce`}
                  />
                </Form.Item>
                <Form.Item
                  name="gr"
                  rules={[
                    {
                      required: true,
                      message: "Bu alan gereklidir!",
                    },
                  ]}
                >
                  <Input
                    className="adminCategoryInput"
                    placeholder={`Alt Kategori Almanca`}
                  />
                </Form.Item>

                <Form.Item
                  wrapperCol={{
                    offset: 0,
                    span: 16,
                  }}
                >
                  <Button
                    htmlType="submit"
                    className="adminCategoryButton"
                    type="primary"
                  >
                    Alt Kategorileri Ekle
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Container>
        </>
      )}
    </>
  );
};

export default AdminCategory;
