import { Card } from "antd";
import React from "react"; 
import { useNavigate } from "react-router-dom";

import "./RelatedProduct.css";
import { useTranslation } from "react-i18next";

const RelatedProduct = ({ data, index }) => {
  const navigate = useNavigate();
  const { i18n } = useTranslation(); 
  const lang = i18n.language;
  return (
    <Card
      key={index}
      onClick={() => navigate(`/products/${data?.id}`)}
      className="relatedProductCard"
    >
      <div className="relatedProductWrapper">
        <img
          className="relatedProductImg"
          src={data?.data.imagesUrl[0]}
          alt="relatedProduct"
        />
        <div className="relatedProductTitle">
          {(lang === "en" && data.data.isimler.en) ||
            (lang === "tr" && data.data.isimler.tr) ||
            (lang === "gr" && data.data.isimler.gr)}
        </div>
      </div>
    </Card>
  );
};

export default RelatedProduct;
