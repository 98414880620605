import { useEffect, useState } from 'react'

export const useWindowScroll = () => {
  const [scrollPosition, setScrollPosition]=useState()

  useEffect(()=>{
    function updatePosition(){
        setScrollPosition(window.scrollY)
    }
    window.addEventListener("scroll", updatePosition)
    updatePosition()

    return ()=> window.removeEventListener("scroll", updatePosition)
  })
  return scrollPosition
}
 