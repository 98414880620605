import { Container } from "@mui/material";
import React from "react";
import { BsGearFill } from "react-icons/bs";
import "./WhatDoWeDo.css";
import { Card } from "antd";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
const WhatDoWeDo = () => {
  const { Meta } = Card;
  const navigate = useNavigate();

  const { i18n } = useTranslation();
  const lang = i18n.language;
  const products = useSelector(({ products }) => products.products);
  const slicedProducts = products.slice(5, 8);

  console.log(slicedProducts);

  return (
    <Container className="what_do_we_do">
      <h3>{t("what-do-we-do-title")}</h3>
      <BsGearFill />
      <h5>{t("what-do-we-do-text")}</h5>
      <div className="what_do_we_do_card_wrapper">
        {slicedProducts.map((product) => (
          <Card
            className="what_do_we_do_card"
            hoverable={false}
            bordered={false}
            onClick={() => navigate(`/products/${product.id}`)}
            cover={
              <img
                className="what_do_we_do_card_image"
                alt="example"
                src={product?.data?.imagesUrl[0]}
              />
            }
          >
            <Meta
              title={
                (lang === "en" && product?.data?.isimler?.en) ||
                (lang === "tr" && product?.data?.isimler?.tr) ||
                (lang === "gr" && product?.data?.isimler?.gr)
              }
            />
          </Card>
        ))}
      </div>
    </Container>
  );
};

export default WhatDoWeDo;
