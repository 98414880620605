import { Container } from "@mui/material";
import React from "react";
import { Card } from "antd";

import "./Card5.css";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const { Meta } = Card;

const Card5 = () => {
  const navigate = useNavigate();

  const { i18n } = useTranslation();
  const lang = i18n.language;
  const products = useSelector(({ products }) => products.products);
  const slicedProducts = products.slice(0, 5);

  return (
    <div className="card5">
      <Container maxWidth="xl">
        <div className="card5_container">
          {slicedProducts.map((product) => (
            <Card
              hoverable={false}
              bordered={false}
              className="card5_card"
              onClick={() => navigate(`/products/${product.id}`)}
              cover={
                <img
                  className="card5_image"
                  alt="example"
                  src={product?.data?.imagesUrl[0]}
                />
              }
            >
              <Meta
                title={
                  (lang === "en" && product?.data?.isimler?.en) ||
                  (lang === "tr" && product?.data?.isimler?.tr) ||
                  (lang === "gr" && product?.data?.isimler?.gr)
                }
              />
            </Card>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default Card5;
