import React from "react";

import Carousel from "nuka-carousel";

import image from "../../public/images/background/bg-1.jpg";

import "./Carousel.css";

import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { Container } from "@mui/material";
import { Fade } from "react-reveal";

const CustomCarousel = () => {
  return (
    <Container maxWidth="xl">
      <Fade bottom>
        <Carousel
          className="carousel"
          renderCenterLeftControls={(e) => (
            <FaArrowLeft
              onClick={() => {
                e.previousSlide();
              }}
              style={{ paddingLeft: ".5rem" }}
              className="carousel__icon"
              size={36}
            />
          )}
          renderCenterRightControls={(e) => (
            <FaArrowRight
              onClick={() => {
                e.nextSlide();
              }}
              style={{ paddingRight: ".5rem" }}
              className="carousel__icon"
              size={36}
            />
          )}
          wrapAround
          autoplay
        >
          <img className="carousel_image" src={image} alt="carousel_1" />
          <img className="carousel_image" src={image} alt="carousel_1" />
          <img className="carousel_image" src={image} alt="carousel_1" />
        </Carousel>
      </Fade>
    </Container>
  );
};

export default CustomCarousel;
