import React, { useEffect, useRef, useState } from "react";
import "./Header.css";
import { useLocation, useNavigate } from "react-router-dom";

import useWindowSize from "../../util/useWindowSize";
import { GiHamburgerMenu } from "react-icons/gi";
import HeaderNav from "./HeaderNav";

import Logo from "../../public/images/logo-yavuz-plastik.svg";
import { useWindowScroll } from "../../util/useWindowScroll";
const Header = ({ isAdminLogin, title }) => {
  const navigate = useNavigate();
  let windowY = window.scrollY;
  const [burgerStatus, setBurgerStatus] = useState(false);

  const size = useWindowSize();

  const location = useLocation();

  useEffect(() => {
    setBurgerStatus(false);
  }, [windowY]);

  useEffect(() => {
    setBurgerStatus(false);
  }, [location.pathname]);

  const nodeRef = useRef(null);

  const scrollY = useWindowScroll();

  return (
    <>
      {/*  <div
        onClick={() => (window.location.href = "tel:+905543016169")}
        className="headerTelephone"
      >
        <IoCall color="white" size={30} />
        <div className="headerTelephoneText">+90 507 702 70 07</div>
      </div> */}
      <div
        style={{
          paddingTop: scrollY !== 0 && "0.5rem",
          paddingBottom: scrollY !== 0 && "0.5rem",
        }}
        className={`headerContainer ${
          location.pathname !== "/main" && "headerContainerExtra"
        }`}
      >
        <div
          style={{
            marginLeft: size.width < 480 ? "1.5rem" : "4.5rem",
            marginRight: size.width < 480 ? "1.5rem" : "3.5rem",
          }}
          className="headerInnerContainer"
        >
          <div onClick={() => navigate("/main")} className="headerLogoWrapper">
            {(scrollY !== 0 || location.pathname !== "/main") && (
              <img className="headerLogoImg" src={Logo} alt="logo" />
            )}
            <div>{title}</div>
          </div>

          <div className="headerLinks">
            {size.width <= 992 ? (
              <>
                <GiHamburgerMenu
                  color="#f6f6f6"
                  size={36}
                  onClick={() => setBurgerStatus(true)}
                />

                <div
                  style={{
                    transform: burgerStatus
                      ? "translateY(0%)"
                      : "translateY(150%)",
                    transition: "transform 0.6s",
                  }}
                  ref={nodeRef}
                  className="HeaderBurgerNavBar"
                >
                  <HeaderNav
                    burgerStatus={true}
                    setBurgerStatus={setBurgerStatus}
                    isAdminLogin={isAdminLogin}
                  />
                </div>
              </>
            ) : (
              <HeaderNav isAdminLogin={isAdminLogin} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
