import React from "react";
import "./Analytics.css";
import { useState } from "react";
import { useEffect } from "react";
import {
  getActiveUsers,
  getAllUsers,
  getPageViews,
} from "../../../util/firebaseFunctions";
import AnalyticsChart from "./AnalyticsChart";
import { Container } from "@mui/material";

const Analytics = () => {
  const [activeUsers, setActiveUsers] = useState(0);
  const [pageViews, setPageViews] = useState();
  const [allUsers, setAllUsers] = useState();
  const [allUsersSize, setAllUsersSize] = useState();
  const [dataFrame, setDataFrame] = useState();

  useEffect(() => {
    getActiveUsers(setActiveUsers);
    getPageViews(setPageViews);
    getAllUsers(setAllUsers, setAllUsersSize);
  }, []);

  function groupBy(data, key) {
    return data?.reduce((result, item) => {
      const groupKey = item[key];
      result[groupKey] = (result[groupKey] || 0) + 1;
      return result;
    }, {});
  }

  useEffect(() => {
    if (allUsers) {
      const groupedData = groupBy(allUsers, "city");
      const dataArray = Object.entries(groupedData).map(([key, value]) => ({
        city: key,
        count: value,
      }));
      setDataFrame(dataArray);
    }
  }, [allUsers]);

  return (
    <Container maxWidth="lg" className="analytics_contanier">
      {/* TOP INFO */}
      <div className="analytics_top_info">
        <div>
          <h3>Online Kullanıcılar</h3>
          <h2>{activeUsers}</h2>
        </div>
        <div className="analytics_card_middle">
          <h3>Toplam Ziyaret Sayısı</h3>
          <h2>{pageViews}</h2>
        </div>
        <div>
          <h3>Toplam Kullanıcı</h3>
          <h2>{allUsersSize}</h2>
        </div>
      </div>
      {/* CHART INFO */}
      <div className="analytics_chart_info">
        <h3>Şehirlere Göre Dağılım</h3>
        <AnalyticsChart data={dataFrame} />
      </div>
    </Container>
  );
};

export default Analytics;
