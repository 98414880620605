import { Button, Card, Form, Input, message, Modal, Select } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import React, { useState } from "react";
import { InboxOutlined } from "@ant-design/icons";

import "./Dashboard.css";
import { writeFirestore } from "../../util/FirebaseFuncs";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import Spinner from "../UI/Spinner";
import { useNavigate } from "react-router-dom";
import DashboardForm from "./DashboardForm";

import { AiOutlineClose } from "react-icons/ai";

const Dashboard = () => {
  const [form] = Form.useForm();
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [allInputs, setAllInputs] = useState([]);
  const [input, setInput] = useState({
    tr: {
      baslık: "",
      deger: "",
    },
    en: {
      baslık: "",
      deger: "",
    },
    gr: {
      baslık: "",
      deger: "",
    },
  });

  const navigate = useNavigate();

  const onFinish = async (values) => {
    const lastObj = {
      kategori: values.kategori,
      altKategori: values.altKategori || "",
      isimler: {
        tr: values.isimTr,
        en: values.isimEn,
        gr: values.isimGr,
      },
      degerler: allInputs,
      resimler: values.files.fileList,
    };
    writeFirestore(lastObj, setIsLoading);

    form.resetFields();
  };

  const onFinishFailed = () => {
    message.error("Ürün Yüklenemedi!");
  };

  //Modal

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    if (
      !input.tr.deger ||
      !input.en.baslık ||
      !input.en.deger ||
      !input.gr.baslık ||
      !input.gr.deger
    ) {
      message.error("Tüm Alanları Doldurun.");
    } else {
      setAllInputs((prev) => [...prev, input]);
      setInput({
        tr: {
          baslık: "",
          deger: "",
        },
        en: {
          baslık: "",
          deger: "",
        },
        gr: {
          baslık: "",
          deger: "",
        },
      });
      setIsModalOpen(false);
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  //Modal End

  // Dashboard Categories

  const getData = async (value) => {
    const productRef = collection(db, value);
    setIsLoading(true);
    const resData = await getDocs(productRef);

    setCategories(
      resData.docs.map((doc) => {
        return doc.data();
      })
    );
    setIsLoading(false);
  };

  // Dashboard Categories End

  const handleChange = (value) => {
    getData(value);
  };

  return (
    <>
      {isLoading ? (
        <Spinner message="Ürün Yükleniyor..." />
      ) : (
        <Card className="dashboardCard">
          <Modal
            title="Özellik Ekleme"
            open={isModalOpen}
            okText="Ekle"
            cancelText="Vazgeç"
            closeIcon={<AiOutlineClose size={24} color="#211c24" />}
            bodyStyle={{
              color: "#211c24",
              fontSize: "1rem",
              borderTop: "1px solid #211c24",
              borderBottom: "1px solid #211c24",
            }}
            okType="text"
            cancelButtonProps={{
              style: { borderColor: "#211c24", color: "#211c24" },
            }}
            okButtonProps={{
              style: { backgroundColor: "#211c24", color: "#f6f6f6" },
            }}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <DashboardForm input={input} setInput={setInput} />
          </Modal>
          <Button
            className={["dashboardAddCategory", "dashboardButton"]}
            type="primary"
            onClick={() => navigate("/admin/category")}
          >
            Alt Kategori Ekleme Paneli
          </Button>

          <div className="dashboardBorder"></div>

          {/* REAL FORM */}
          <Form
            form={form}
            name="basic"
            wrapperCol={{
              span: 24,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              name="kategori"
              rules={[
                {
                  required: true,
                  message: "Kategori gereklidir!",
                },
              ]}
            >
              <Select
                placeholder="Kategori"
                style={{
                  width: 200,
                }}
                onChange={handleChange}
                options={[
                  { value: "şişeler", label: "Şişeler" },
                  { value: "kapaklar", label: "Kapaklar" },
                  { value: "preformlar", label: "Preformlar" },
                  { value: "spreyler", label: "Spreyler" },
                  { value: "pompalar", label: "Pompalar" },
                ]}
              />
            </Form.Item>
            <Form.Item
              name="files"
              valuePropName="drag"
              rules={[
                {
                  required: true,
                  message: "Resim gereklidir!",
                },
              ]}
            >
              <Dragger
                className="dashboardDragger"
                beforeUpload={() => false}
                name="file"
                multiple
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined style={{ color: "#211c24" }} />
                </p>
                <p className="ant-upload-text">
                  Fotoğraf eklemek için tıklayın yada sürükleyin
                </p>
              </Dragger>
            </Form.Item>

            <Form.Item
              name="isimTr"
              rules={[
                {
                  required: true,
                  message: "Ürün ismi gereklidir!",
                },
              ]}
            >
              <Input className="dashboardItem" placeholder="Türkçe Ürün İsmi" />
            </Form.Item>
            <Form.Item
              name="isimEn"
              rules={[
                {
                  required: true,
                  message: "Ürün ismi gereklidir!",
                },
              ]}
            >
              <Input
                className="dashboardItem"
                placeholder="İngilizce Ürün İsmi"
              />
            </Form.Item>
            <Form.Item
              name="isimGr"
              rules={[
                {
                  required: true,
                  message: "Ürün ismi gereklidir!",
                },
              ]}
            >
              <Input
                className="dashboardItem"
                placeholder="Almanca Ürün İsmi"
              />
            </Form.Item>
            <Form.Item
              name="altKategori"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Select
                notFoundContent={
                  <div style={{ color: "#211c24" }}>
                    Alt Kategori Bulunamadı.
                  </div>
                }
                className="dashboardItem"
                placeholder="Varsa Alt Kategori"
                style={{
                  width: "100%",
                }}
                options={categories.map((cat) => {
                  return {
                    value: cat.tr,
                    label: cat.tr,
                  };
                })}
              />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                className={["dashboardButton", "sendButton"]}
                htmlType="submit"
              >
                Gönder
              </Button>
            </Form.Item>
          </Form>
          <Button
            className="dashboardButton"
            type="primary"
            onClick={showModal}
          >
            Özellik Ekle
          </Button>
          <div style={{ marginTop: "1rem" }}>
            <div
              style={{
                fontSize: "1.4rem",
                borderBottom: "1px solid #211c24",
                paddingBottom: "0.5rem",
              }}
            >
              Eklenilen Özellikler
            </div>
            {allInputs.length < 1 ? (
              <div className="dashboardNoInputsText">
                Henüz Herhangi Bir Özellik Eklemediniz!
              </div>
            ) : (
              allInputs.map((input) => (
                <div className="eklenilenAlanlarWrapper">
                  <div className="eklenilenAlanlar">
                    <div className="eklenilenAlanBaslık">{input.tr.baslık}</div>
                    <div className="eklenilenAlanDeger">{input.tr.deger}</div>
                  </div>
                  <div className="eklenilenAlanlar">
                    <div className="eklenilenAlanBaslık">{input.en.baslık}</div>
                    <div className="eklenilenAlanDeger">{input.en.deger}</div>
                  </div>
                  <div className="eklenilenAlanlar">
                    <div className="eklenilenAlanBaslık">{input.gr.baslık}</div>
                    <div className="eklenilenAlanDeger">{input.gr.deger}</div>
                  </div>
                </div>
              ))
            )}
          </div>
        </Card>
      )}
    </>
  );
};

export default Dashboard;
