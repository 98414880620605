import "./UrunDetay.css";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container } from "@mui/material";
import { Button, Card, Carousel, Col, message, Popconfirm, Row } from "antd";
import { useSelector } from "react-redux";
import { collection, deleteDoc, doc, getDocs } from "firebase/firestore";
import { db } from "../../firebase";

import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { deleteObject, getStorage, ref } from "firebase/storage";
import RelatedProduct from "./RelatedProduct";

import Spinner from "../../components/UI/Spinner";
import { useTranslation } from "react-i18next";
import useWindowSize from "../../util/useWindowSize";
import { Helmet } from "react-helmet";
import { Fade } from "react-reveal";

const UrunDetay = () => {
  const params = useParams();
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const isAdminLogin = useSelector(({ admin }) => admin.admin);
  /*  const lang = useSelector(({ admin }) => admin.whichLang); */
  const lang = i18n.language;
  const size = useWindowSize();

  const getData = async () => {
    const productRef = collection(db, "products");
    setIsLoading(true);
    const resData = await getDocs(productRef);
    setData(
      resData.docs.map((doc) => {
        return { data: doc.data(), id: doc.id };
      })
    );
    setIsLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      getData();
    }, 500);
  }, []);

  const findedData = data.find((d) => d.id === params.id);

  const carouselRef = useRef(null);

  // Urun Silme

  const showPopconfirm = () => {
    setOpen(true);
  };
  const handleOk = async () => {
    setConfirmLoading(true);

    const storage = getStorage();

    findedData.data.imagesUrl.forEach((imageUrl) => {
      const storageRef = ref(storage, imageUrl);
      deleteObject(storageRef);
    });

    const userDoc = doc(db, "products", findedData.id);
    await deleteDoc(userDoc);

    setOpen(false);
    setConfirmLoading(false);

    message.success("Ürün Silindi!");
    navigate("/urunlerimiz");
  };

  const handleCancel = () => {
    setOpen(false);
  };

  // Urun Silme End

  return (
    <Container disableGutters>
      {isLoading ? (
        <Spinner />
      ) : !findedData ? (
        <div className="urunDetay404">{t("product-detail-404")}</div>
      ) : (
        <>
          <Row className="urunDetayInfoWrapper">
            <Helmet>
              <title>
                {(lang === "en" && findedData.data.isimler?.en) ||
                  (lang === "tr" && findedData.data.isimler?.tr) ||
                  (lang === "gr" && findedData.data.isimler?.gr)}
              </title>
              <meta
                name="description"
                content={`${t("metaUrunDetay1")} ${
                  findedData?.data.isimler.tr
                }. ${t("metaUrunDetay2")}`}
              />
              <meta name="keywords" content={t("keywordUrunDetay")} />
            </Helmet>
            <Col xs={24} xl={12} className="carouselWrapper">
              {size.width > 480 && findedData?.data?.imagesUrl.length > 1 && (
                <AiOutlineArrowLeft
                  color="#211c24"
                  className="urunDetayArrow"
                  size={36}
                  onClick={() => carouselRef.current.prev()}
                />
              )}
              <Fade bottom duration={1250}>
                <Carousel
                  ref={carouselRef}
                  className="urunDetayCarousel"
                  effect="scrollx"
                >
                  {findedData?.data?.imagesUrl?.map((i, index) => (
                    <img
                      key={index}
                      src={i}
                      alt="detay"
                      className="urunDetayImg"
                    />
                  ))}
                </Carousel>
              </Fade>
              {size.width > 480 && findedData?.data?.imagesUrl.length > 1 && (
                <AiOutlineArrowRight
                  className="urunDetayArrow"
                  color="#211c24"
                  size={36}
                  onClick={() => carouselRef.current.next()}
                />
              )}
            </Col>
            <Col xs={24} xl={10} className="urunDetayTableWrapper">
              <Fade right duration={1250}>
                <div className="urunDetayTableTitle">
                  {(lang === "en" && findedData.data.isimler?.en) ||
                    (lang === "tr" && findedData.data.isimler?.tr) ||
                    (lang === "gr" && findedData.data.isimler?.gr)}
                </div>
              </Fade>

              {findedData?.data?.degerler.map((d, index) => {
                return (
                  <Card key={index} className="urunDetayTableCard">
                    <div className="urunDetayTableInfoWrapper">
                      <div className="urunDetayTableSubtitle">
                        {(lang === "en" && d?.en?.baslık) ||
                          (lang === "tr" && d?.tr?.baslık) ||
                          (lang === "gr" && d?.gr?.baslık)}
                      </div>
                      <div className="urunDetayTableText">
                        {(lang === "en" && d?.en?.deger) ||
                          (lang === "tr" && d?.tr?.deger) ||
                          (lang === "gr" && d?.gr?.deger)}
                      </div>
                    </div>
                  </Card>
                );
              })}

              {isAdminLogin && (
                <>
                  <Popconfirm
                    title="Ürünü silmek istediğinizden emin misiniz?"
                    okText="Sil"
                    okType="danger"
                    cancelText="Vazgeç"
                    open={open}
                    onConfirm={handleOk}
                    okButtonProps={{
                      loading: confirmLoading,
                    }}
                    onCancel={handleCancel}
                  >
                    <Button
                      className="urunDetayUrunSilButton"
                      type="danger"
                      onClick={showPopconfirm}
                    >
                      {t("productDetailDelete")}
                    </Button>
                  </Popconfirm>
                </>
              )}
            </Col>
          </Row>
          <div className="urunDetayRelatedWrapper">
            <Fade left duration={1500}>
              <div className="urunDetayRelatedTitle">
                {t("related-products")}
              </div>
            </Fade>
            <div className="urunDetayRelatedProducts">
              {data
                ?.map((data, index) => (
                  <Fade bottom duration={1000 + 250 * index}>
                    <RelatedProduct key={index} data={data} />
                  </Fade>
                ))
                .slice(0, 4)}
            </div>
          </div>
        </>
      )}
    </Container>
  );
};

export default UrunDetay;
