export const getClientInfo = async () => {
  try {
    const response = await fetch("https://api-bdc.net/data/client-info");

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    // Process the data returned from the API
    return data;
  } catch (error) {
    // Handle any errors that occurred during the fetch
    console.error("Fetch error:", error);
  }
};
export const getClientGeolocation = async () => {
  try {
    const response = await fetch(
      "https://api.bigdatacloud.net/data/reverse-geocode-client"
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    // Process the data returned from the API
    return data;
  } catch (error) {
    // Handle any errors that occurred during the fetch
    console.error("Fetch error:", error);
  }
};

export const postTotalUserToNode = async (data, setDataFrame) => {
  const response = await fetch("http://localhost:9000/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const content = await response.json();

  setDataFrame(JSON.parse(content));
};
