import { message } from "antd";
import { addDoc, collection } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { db, storage } from "../firebase";

export const uploadImage = async (files) => {
  if (files === []) return;
  //
  return Promise.all(
    files.map(async (item) => {
      const imageRef = ref(storage, `/images/${item.name}`);
      const response = await uploadBytes(imageRef, item.originFileObj);
      const responseUrl = await getDownloadURL(response.ref);
      return responseUrl;
    })
  );
};

export const writeFirestore = async (lastObj, setIsLoading) => {
  setIsLoading(true);
  const productRef = collection(db, "products");
  try {
    const url = await uploadImage(lastObj.resimler);
    delete lastObj.resimler;
    lastObj.imagesUrl = url;
    await addDoc(productRef, lastObj);
    message.success("Ürün Başarıyla Yüklendi!");
  } catch (error) {
    message.error("Ürün Yüklenemedi!");
  }
  setIsLoading(false);
};
