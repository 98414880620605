import { Card } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import "./UrunInfo.css";

const UrunInfo = ({ size }) => {
  const { t } = useTranslation();
  return (
    <Card className="urunInfoCard">
      <div className="urunInfoWrapper">
        <div className="urunInfoInfo">
          {t("products-total")} {size} {t("products-total-prod")}
        </div>
      </div>
    </Card>
  );
};

export default UrunInfo;
