import { Button, Form, Input, message } from "antd";
import React, { useRef } from "react";
import "./CustomForm.css";
import { IoIosSend } from "react-icons/io";
import emailjs from "@emailjs/browser";
import { t } from "i18next";

const CustomForm = ({ setIsLoading }) => {
  const [form] = Form.useForm();
  const formRef = useRef();

  const onFinish = async (values) => {
    setIsLoading(true);
    await emailjs.send(
      process.env.REACT_APP_EMAIL_1,
      process.env.REACT_APP_EMAIL_2,
      values,
      process.env.REACT_APP_EMAIL_3
    );
    setIsLoading(false);
    message.success(t("formSuccesMessage"));
    form.resetFields();
  };

  return (
    <Form
      size="large"
      className="customForm"
      form={form}
      ref={formRef}
      name="control-hooks"
      onFinish={onFinish}
      layout="vertical"
    >
      <Form.Item
        label={t("contactsFormName")}
        name="adSoyad"
        rules={[
          {
            required: true,
            message: "Ad Soyad Gereklidir!",
          },
        ]}
      >
        <Input className="formInput" />
      </Form.Item>
      <Form.Item
        label={t("contactsFormPhone")}
        name="telefon"
        rules={[
          {
            required: true,
            message: "Telefon Numarası Gereklidir!",
          },
        ]}
      >
        <Input className="formInput" />
      </Form.Item>
      <Form.Item
        label={t("contactsFormMail")}
        name="ePosta"
        rules={[
          {
            required: true,
            message: "E-Posta Gereklidir!",
          },
        ]}
      >
        <Input className="formInput" type="email" />
      </Form.Item>
      <Form.Item
        label={t("contactsFormMessage")}
        name="message"
        rules={[
          {
            required: true,
            message: "Mesaj Gereklidir!",
          },
        ]}
      >
        <Input.TextArea
          autoSize={{ minRows: 8, maxRows: 16 }}
          className="formInput"
        />
      </Form.Item>

      <Form.Item>
        <Button className="formSubmitButton" type="primary" htmlType="submit">
          {t("contactsFormSend")}
          <IoIosSend size={24} />
        </Button>
      </Form.Item>
    </Form>
  );
};
export default CustomForm;
