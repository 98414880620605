import React from "react";
import "./WhoWeAre.css";
import { Container } from "@mui/material";
import { BsFacebook, BsInstagram, BsWhatsapp } from "react-icons/bs";
import image from "../../public/images/background/bg-7.jpg";
import { t } from "i18next";
import { Fade } from "react-reveal";
const WhoWeAre = () => {
  return (
    <div className="who_we_are">
      <Container maxWidth="md">
        <Fade top>
          <h3>{t("main-title")}</h3>
        </Fade>
        <Fade bottom>
          <p>{t("main-info")}</p>
        </Fade>
        <div className="who_we_are_img_icons_wrapper">
          <img src={image} alt="bizKimizResim" />
          <div>
            <Fade right duration={1200}>
              <BsFacebook
                className="who_svg"
                onClick={() =>
                  window.open("https://www.facebook.com/Yavuzplastik.22/")
                }
              />
            </Fade>
            <Fade right duration={1300}>
              <BsInstagram
                className="who_svg"
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/yavuz_plastik32/?hl=en"
                  )
                }
              />
            </Fade>
            <Fade right duration={1400}>
              <BsWhatsapp
                className="who_svg"
                onClick={() =>
                  window.open(
                    "https://api.whatsapp.com/send/?phone=905443016169&text&type=phone_number&app_absent=0"
                  )
                }
              />
            </Fade>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default WhoWeAre;
